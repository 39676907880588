import React, { useEffect, useReducer, useRef, useState, useLayoutEffect } from "react";
import { MainLayout, SidebarLayout } from "../layouts";
import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Tr,
  Th,
  Td,
  Box,
  Tab,
  Tag,
  Tabs,
  Text,
  Icon,
  Flex,
  Menu,
  Table,
  Thead,
  Tbody,
  Stack,
  Input,
  VStack,
  Select,
  Button,
  HStack,
  Center,
  TabList,
  Heading,
  LinkBox,
  useToast,
  TabPanel,
  Skeleton,
  MenuList,
  MenuItem,
  Progress,
  FormLabel,
  TabPanels,
  MenuButton,
  FormControl,
  LinkOverlay,
  useDisclosure,
  FormErrorMessage,
  useColorModeValue,
} from "@chakra-ui/react";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { CalendarIcon } from '@chakra-ui/icons';
import {
  CustomTag,
  Pagination,
  ModalDialog,
  TableQueryBuilder,
  SavedQueriesTable,
} from "../components";
import {
  ApiPath,
  columns,
  FETCH_INIT,
  FETCH_SUCCESS,
  FETCH_FAILURE,
  SET_TABLE,
  SET_QUERY,
  SET_TAB,
  SET_SQL_QUERY,
  QUERY_INIT,
  SET_SAVED_QUERIES,
  ADD_SAVED_QUERY,
  UPDATE_SAVED_QUERY,
  SET_SAVED_QUERY_STATUS,
  SET_BUILDER_MODE,
  DELETE_SAVED_QUERY,
  ERROR,
  SET_EXPORT_DATA,
} from "../constants";
import { Light as SyntaxHighlighter } from "react-syntax-highlighter";
import { sql } from "react-syntax-highlighter/dist/esm/languages/hljs";
import {
  vs2015,
  arduinoLight,
} from "react-syntax-highlighter/dist/esm/styles/hljs";
import { usePagination } from "@ajna/pagination";
import { formatQuery } from "react-querybuilder";
import { format } from "sql-formatter";
import { AiFillCheckCircle } from "react-icons/ai";
import { BiExport } from "react-icons/bi";
import { IoBuildOutline } from "react-icons/io5";
import { HiViewList } from "react-icons/hi";
import { CSVLink } from "react-csv";
import { useUser } from "../utils";
import { useForm } from "react-hook-form";
import { AttributeValue } from "dynamodb-data-types";
import { parseISO, format as dateFormat, set } from "date-fns";

const tables = [
  "zbl_data",
  "zkb_data",
  "vcs_data",
  "nissan_pin",
  "v_dwh_vpo_stat",
  "ascis",
  "csb_gsb_codierstringbuilder_request",
];

const groupedTables = {
  "ZBL": ["zbl_data"],
  "ZKB": ["zkb_data"],
  "VCS": ["vcs_data"],
  "Nissan Pin": ["nissan_pin"],
  "VPO-STAT / FCSC": ["v_dwh_vpo_stat", "t_finlog"],
  "ASCIS": ["ascis"],
  "CSB-GSB": ["csb_gsb_codierstringbuilder_request"],
};

function HoverMenu({ category, tables, onChange }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box position="relative" onMouseEnter={onOpen} onMouseLeave={onClose}>
      <MenuItem onClick={() => onChange(tables[0])}>{category}</MenuItem>

      {isOpen && (
        <MenuList
          position="absolute"
          left="100%"
          top="0"
          minW="150px"
          zIndex="10"
        >
          {tables.map((table) => (
            <MenuItem value={table} key={table} onClick={() => onChange(table)}>
              {table}
            </MenuItem>
          ))}
        </MenuList>
      )}
    </Box>
  );
}

const tableDateColumns = {
  zbl_data: "creation_date",
  zkb_data: "creation_date",
  vcs_data: "creation_date",
  nissan_pin: "creation_date",
  v_dwh_vpo_stat: "onlydate",
  ascis: "client_requestcreationtime",
  csb_gsb_codierstringbuilder_request: "time_stamp",
  t_finlog: "c_create_timestamp"
};

const getDateColumnName = (tableName) => tableDateColumns[tableName];

// const creation_date_tables = ["zbl_data",
//   "zkb_data",
//   "vcs_data",
//   "nissan_pin"]

const schemaName = process.env.REACT_APP_SCHEMA;

SyntaxHighlighter.registerLanguage("sql", sql);

const generateID = () => Math.random().toString();

const CustomTabPanel = ({ content, button }) => (
  <VStack
    align="flex-start"
    h="55vh"
    p={2}
    maxH="55vh"
    border="1px"
    borderColor={useColorModeValue("gray.300", "whiteAlpha.300")}
  >
    <Box h="50vh" maxH="50vh" overflowY="auto" w="full" p={1}>
      {content}
    </Box>
    {button}
  </VStack>
);

let controller;

const QueryPage = () => {
  const mobileSideNav = useDisclosure();
  const user = useUser();
  const csvLink = useRef();

  const {
    isOpen: isBuildModalOpen,
    onOpen: onBuildModalOpen,
    onClose: onBuildModalClose,
  } = useDisclosure();

  const {
    isOpen: isExportModalOpen,
    onOpen: onExportModalOpen,
    onClose: onExportModalClose,
  } = useDisclosure();

  const {
    isOpen: isSaveModalOpen,
    onOpen: onSaveModalOpen,
    onClose: onSaveModalClose,
  } = useDisclosure();

  const {
    isOpen: isQueriesModalOpen,
    onOpen: onQueriesModalOpen,
    onClose: onQueriesModalClose,
  } = useDisclosure();

  const toast = useToast({
    duration: 4000,
    isClosable: true,
    position: "bottom-right",
    variant: "solid",
  });

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
    reset,
  } = useForm();

  const initialState = {
    selectedTab: 0,
    selectedTable: "zbl_data",
    columns: columns["zbl_data"],
    query: {
      id: `g-${generateID()}`,
      combinator: "and",
      not: false,
      rules: [],
    },
    lastRunQuery: null,
    tableData: [],
    totalRows: null,
    tableColumns: [],
    isLoading: false,
    isQuerying: false,
    exportData: [],
    savedQueries: [],
    mode: { type: "new", query: {} },
    loadingSavedQueries: true,
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case FETCH_INIT:
        return {
          ...state,
          isLoading: true,
        };
      case FETCH_SUCCESS:
        return {
          ...state,
          tableData: action.payload.rows,
          tableColumns: action.payload.columns,
          totalRows: action.payload.totalRows,
          isLoading: false,
          isQuerying: false,
        };
      case FETCH_FAILURE:
        return {
          ...state,
          isLoading: false,
          isQuerying: false,
        };
      case SET_TABLE:
        return {
          ...state,
          selectedTable: action.payload,
          columns: columns[action.payload],
          query: {
            id: `g-${generateID()}`,
            combinator: "and",
            not: false,
            rules: [],
          },
          tableData: [],
          tableColumns: [],
          totalRows: null,
        };
      case SET_QUERY:
        return {
          ...state,
          lastRunQuery: action.payload,
        };
      case SET_TAB:
        return {
          ...state,
          selectedTab: action.payload,
        };
      case SET_SQL_QUERY:
        return {
          ...state,
          query: action.payload,
        };
      case QUERY_INIT:
        return {
          ...state,
          isQuerying: true,
        };
      case SET_EXPORT_DATA:
        return {
          ...state,
          exportData: action.payload,
        };
      case SET_SAVED_QUERIES:
        return {
          ...state,
          savedQueries: action.payload,
          loadingSavedQueries: false,
        };
      case ADD_SAVED_QUERY:
        return {
          ...state,
          savedQueries: [...state.savedQueries, action.payload],
        };
      case UPDATE_SAVED_QUERY:
        return {
          ...state,
          savedQueries: state.savedQueries.map((item) =>
            item.queryName === state.mode.query.queryName
              ? {
                ...item,
                query: JSON.stringify(state.query),
                isUpdating: false,
              }
              : item
          ),
        };
      case SET_SAVED_QUERY_STATUS:
        return {
          ...state,
          savedQueries: state.savedQueries.map((item) =>
            item.queryName === action.queryName
              ? { ...item, [action.status]: !item[action.status] }
              : item
          ),
        };
      case SET_BUILDER_MODE: {
        return {
          ...state,
          mode: action.payload,
        };
      }
      case DELETE_SAVED_QUERY:
        return {
          ...state,
          savedQueries: state.savedQueries.filter(
            (item) => item.queryName !== action.queryName
          ),
        };

      default:
        throw new Error("Missing dispatch action");
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const outerLimit = 1;
  const innerLimit = 1;

  const { pages, pagesCount, offset, currentPage, setCurrentPage } =
    usePagination({
      total: state.totalRows,
      limits: {
        outer: outerLimit,
        inner: innerLimit,
      },
      initialState: {
        pageSize: 50,
        currentPage: 1,
      },
    });

  const queryTable = async (query = null) => {
    let didCancel = false;

    dispatch({ type: FETCH_INIT });
    try {
      let selectedQuery;
      if (query) {
        setCurrentPage(1);
        selectedQuery = query;
        dispatch({ type: SET_QUERY, payload: query });
      } else {
        selectedQuery = state.lastRunQuery;
      }
      const data = JSON.stringify({
        userQuery: selectedQuery,
        offset,
      });
      const c = JSON.stringify({
        userQuery: selectedQuery.replace(/SELECT \*/i, "SELECT COUNT(*)"),
        offset,
      });
      let response1 = await fetch(ApiPath.query, {
        method: "Post",
        body: c,
      });
      const response2 = await fetch(ApiPath.query, {
        method: "Post",
        body: data,
      });
      if (!response2.ok) {
        throw new Error(response2.status);
      }
      if (!response1.ok && response2.ok) {
        let tryAgainCount = 5;
        while (!response1.ok && tryAgainCount > 0 && response1.status === 504) {
          response1 = await fetch(ApiPath.query, {
            method: "Post",
            body: c,
          });
          tryAgainCount--;
        }
      }
      const queryResult1 = await response1.json();
      const queryResult2 = await response2.json();
      const { totalRows } = queryResult1;
      const { rows, columnNames } = queryResult2;
      if (!rows.length) {
        toast({
          description: "Query returned 0 rows",
          status: "warning",
        });
      }

      if (!didCancel) {
        dispatch({
          type: FETCH_SUCCESS,
          payload: {
            rows,
            columns: columnNames,
            totalRows,
          },
        });
      }
    } catch (err) {
      console.error(err);
      if (!didCancel) {
        dispatch({ type: FETCH_FAILURE });
        toast({
          description: ERROR,
          status: "error",
        });
      }
    }

    return () => {
      didCancel = true;
    };
  };

  const useDidMountEffect = (func, deps = []) => {
    const didMount = useRef(false);

    useLayoutEffect(() => {
      if (didMount.current) func();
      else didMount.current = true;
    }, deps);
  };

  useDidMountEffect(() => {
    queryTable();
  }, [currentPage]);

  const brandColor = useColorModeValue("brand.500", "brand.200");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.300");
  const bg = useColorModeValue("gray.50", "gray.700");
  const tableBodyBg = useColorModeValue("white", "gray.800");
  const codeTheme = useColorModeValue(arduinoLight, vs2015);
  const secondaryTextColor = useColorModeValue("gray.600", "gray.400");

  const handlePageChange = async (nextPage) => {
    setCurrentPage(nextPage);
  };
  const [inputDateStart, setInputDateStart] = useState(new Date().setDate(new Date().getDate() - 15));
  const [inputDateEnd, setInputDateEnd] = useState(new Date());
  const [ISOInputDateQuery, setISOInputDateQuery] = useState(`(${getDateColumnName(state.selectedTable)} >='${inputDateStart}' AND ${getDateColumnName(state.selectedTable)} <= '${inputDateEnd}')`);
  const sqlPreviewQuery = state.query.rules.length
    ? `SELECT * from ${schemaName}.${state.selectedTable} WHERE ${formatQuery(
      state.query,
      "sql"
    )}`
    // : creation_date_tables.includes(state.selectedTable) ? 
    : `SELECT * from ${schemaName}.${state.selectedTable} WHERE ${ISOInputDateQuery}`
  // :`SELECT * from ${schemaName}.${state.selectedTable}`;

  const QueryBuilderActionButton = ({ type }) => {
    if (type === "new") {
      return (
        <Button
          alignSelf="flex-end"
          colorScheme="gray"
          size="sm"
          onClick={onSaveModalOpen}
          disabled={state.isQuerying || !iterate(state.query)}
        >
          Save Query
        </Button>
      );
    } else {
      return (
        <Button
          alignSelf="flex-end"
          colorScheme="gray"
          size="sm"
          onClick={updateSavedQuery}
          isLoading={
            state.savedQueries.find(
              (item) => item.queryName === state.mode.query.queryName
            ).isUpdating
          }
          disabled={state.isQuerying || !iterate(state.query)}
        >
          Update Query
        </Button>
      );
    }
  };

  const abortExportData = () => {
    dispatch({ type: SET_EXPORT_DATA, payload: [] });
    controller.abort();
    onExportModalClose();
    toast({
      description: "Export cancelled",
      status: "warning",
    });
  };

  const isIsoDate = (date) => {
    if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(date)) return false;
    var d = new Date(date);
    return d.toISOString() === date;
  };

  const validDatetime = (datetime) => {
    if (isIsoDate(datetime)) {
      const ISTDate = parseISO(datetime);
      const formattedDate = dateFormat(new Date(ISTDate.getTime() - (5 * 60 + 30) * 60000), "yyyy-MM-dd HH:mm:ss");
      return formattedDate;
    } else {
      return datetime;
    }
  };

  // const handleDateChangeStart = (event) => {
  //   setInputDateStart(event.target.value);
  // };


  // const handleDateChangeEnd = (event) => {
  //   setInputDateEnd(event.target.value);
  // };

  useEffect(() => {
    // const [year, month] = inputDate.split('-').map(Number);

    // // Create date objects for the first and last day of the month
    // const startDate = new Date(Date.UTC(year, month - 1, 1));
    // const endDate = new Date(Date.UTC(year, month, 0, 23, 59, 59, 999));

    // // Format dates to ISO strings
    // const startISO = startDate.toISOString();
    // const endISO = endDate.toISOString();
    if (inputDateStart && inputDateEnd)
      setISOInputDateQuery(
        `(${getDateColumnName(state.selectedTable)} >='${dateFormat(inputDateStart, 'yyyy-MM-dd')}' AND ${getDateColumnName(state.selectedTable)} <= '${dateFormat(inputDateEnd, 'yyyy-MM-dd')}')`
      );
  }, [inputDateStart, inputDateEnd, state.selectedTable]);

  const QueryResultsTable = () => (
    <Box
      w="full"
      h={{ base: "calc(100vh - 10rem)", md: "calc(100vh - 9rem)" }}
      maxH={{ base: "calc(100vh - 9rem)", md: "calc(100vh - 9rem)" }}
      overflow="hidden"
      borderWidth="1px"
      borderColor={borderColor}
      bg={bg}
    >
      <Skeleton
        isLoaded={!state.isLoading}
        height={{ base: "calc(100vh - 9rem)", md: "calc(100vh - 9rem)" }}
        w="full"
        overflow="auto"
      >
        <Table size="sm" variant="unstyled">
          <Thead
            pos="sticky"
            top="0"
            borderBottomWidth="1px"
            borderColor={borderColor}
          >
            <Tr>
              {state.tableColumns.map((header) => (
                <Th
                  key={header}
                  bg={bg}
                  borderRightWidth="1px"
                  borderBottomWidth="1px"
                  borderColor={borderColor}
                >
                  {header}
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody bg={tableBodyBg}>
            {state.tableData.map((row, i) => (
              <Tr
                key={row.id}
                borderBottomWidth="1px"
                borderColor={borderColor}
              >
                {state.columns.map((column) => (
                  <Td
                    key={column.name}
                    borderRightWidth="1px"
                    width="100%"
                    sx={{ whiteSpace: "nowrap" }}
                    borderColor={borderColor}
                  >
                    {(column.inputType === "datetime-local" && row[column.name] !== null)
                      ? validDatetime(row[column.name]) :
                      (column.inputType === "date" && row[column.name] !== null)
                        ? dateFormat(new Date(row[column.name]), "yyyy-MM-dd") :
                        row[column.name] === true ? "true" : row[column.name] === false ? "false" :
                          row[column.name] !== null
                            ? row[column.name]
                            : "{N/A}"}
                  </Td>
                ))}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Skeleton>
    </Box>
  );

  const handleTableChange = (event) => {
    dispatch({ type: SET_TABLE, payload: event });
    mobileSideNav.onClose();
  };

  const handleOnQueryChange = (query) => {
    dispatch({
      type: SET_SQL_QUERY,
      payload: query,
    });
  };

  let validQuery = true;
  const iterate = (obj) => {
    Object.keys(obj).forEach((key) => {
      if (key === "value" && obj[key] === "") {
        if (!(obj["operator"] === "null" || obj["operator"] === "notNull")) {
          validQuery = false;
        }
      }

      if (typeof obj[key] === "object") {
        iterate(obj[key]);
      }
    });
    return validQuery;
  };

  const runQuery = async () => {
    dispatch({
      type: QUERY_INIT,
    });
    await queryTable(sqlPreviewQuery);
    onBuildModalClose();
  };

  const handleExportTable = async () => {
    if (pagesCount > 200) {
      toast({
        description: "Query results exceeds the export limit of 10000 rows",
        status: "error",
      });
    } else {
      controller = new AbortController();
      const signal = controller.signal;
      onExportModalOpen();

      let currentOffset = 0;
      let page = 1;
      let dataToExport = [];

      while (page <= pagesCount) {
        const pageData = JSON.stringify({
          userQuery: state.lastRunQuery,
          offset: currentOffset,
        });
        const response = await fetch(ApiPath.query, {
          method: "Post",
          body: pageData,
          signal,
        });
        if (!response.ok) {
          throw new Error(response.status);
        }
        const queryResult = await response.json();
        const { rows } = queryResult;
        dataToExport.push(...rows);
        dispatch({ type: SET_EXPORT_DATA, payload: dataToExport });
        page++;
        currentOffset += 50;
      }
      onExportModalClose();
      csvLink.current.link.click();
      dispatch({ type: SET_EXPORT_DATA, payload: [] });
    }
  };

  const runSavedQuery = async (query) => {
    dispatch({
      type: SET_SAVED_QUERY_STATUS,
      queryName: query.queryName,
      status: "isExecuting",
    });

    const parsedQuery = JSON.parse(query.query);

    await queryTable(
      `SELECT * from ${schemaName}.${state.selectedTable} WHERE ${formatQuery(
        parsedQuery,
        "sql"
      )}`
    );
    dispatch({
      type: SET_SAVED_QUERY_STATUS,
      queryName: query.queryName,
      status: "isExecuting",
    });
    onQueriesModalClose();
  };

  const editSavedQuery = async (query) => {
    dispatch({
      type: SET_BUILDER_MODE,
      payload: { type: "edit", query },
    });

    const parsedQuery = JSON.parse(query.query);
    dispatch({
      type: SET_SQL_QUERY,
      payload: parsedQuery,
    });
    onQueriesModalClose();
    onBuildModalOpen();
  };

  const resetMode = () => {
    const initialQuery = {
      id: `g-${generateID()}`,
      combinator: "and",
      not: false,
      rules: [],
    };
    dispatch({
      type: SET_SQL_QUERY,
      payload: initialQuery,
    });
    dispatch({
      type: SET_BUILDER_MODE,
      payload: { type: "new", query: {} },
    });
    onBuildModalClose();
  };

  // CRUD methods for Saved Queries

  const createSavedQuery = async (values) => {
    try {
      const queryData = {
        userID: user.userID,
        queryName: `${values.name}_${state.selectedTable}`,
        name: values.name,
        table: state.selectedTable,
        query: JSON.stringify(state.query),
      };

      const data = JSON.stringify(AttributeValue.wrap(queryData));
      const response = await fetch(ApiPath.userQuery, {
        method: "POST",
        body: data,
      });
      await response.json();
      dispatch({ type: ADD_SAVED_QUERY, payload: queryData });
      onSaveModalClose();
      toast({
        description: "Query saved",
        status: "success",
      });
      reset();
    } catch (err) {
      console.error(err);
      toast({
        description: ERROR,
        status: "error",
      });
    }
  };

  useEffect(() => {
    async function fetchSavedQueries() {
      const response = await fetch(
        `${ApiPath.userQuery}?userID=${user.userID}`,
        {
          method: "GET",
        }
      );
      const result = await response.json();
      const queries = result.queries.map((item) => ({
        ...AttributeValue.unwrap(item),
        isExecuting: false,
        isUpdating: false,
        isDeleting: false,
      }));
      dispatch({ type: SET_SAVED_QUERIES, payload: queries });
    }

    fetchSavedQueries();
  }, [user.userID]);

  const updateSavedQuery = async () => {
    try {
      dispatch({
        type: SET_SAVED_QUERY_STATUS,
        queryName: state.mode.query.queryName,
        status: "isUpdating",
      });

      const queryData = {
        userID: user.userID,
        queryName: state.mode.query.queryName,
        table: state.selectedTable,
        query: JSON.stringify(state.query),
      };

      const data = JSON.stringify(AttributeValue.wrap(queryData));
      const response = await fetch(ApiPath.userQuery, {
        method: "PUT",
        body: data,
      });
      await response.json();
      dispatch({ type: UPDATE_SAVED_QUERY });
      onSaveModalClose();
      toast({
        description: "Query updated",
        status: "success",
      });
    } catch (err) {
      console.error(err);
      toast({
        description: ERROR,
        status: "error",
      });
    }
  };

  const deleteSavedQuery = async (query) => {
    try {
      dispatch({
        type: SET_SAVED_QUERY_STATUS,
        queryName: query.queryName,
        status: "isDeleting",
      });
      const queryData = {
        userID: user.userID,
        queryName: `${query.name}_${state.selectedTable}`,
      };

      const data = JSON.stringify(AttributeValue.wrap(queryData));
      const response = await fetch(ApiPath.userQuery, {
        method: "DELETE",
        body: data,
      });
      await response.json();
      dispatch({ type: DELETE_SAVED_QUERY, queryName: query.queryName });
      toast({
        description: "Query deleted",
        status: "info",
      });
    } catch (err) {
      console.error(err);
      toast({
        description: ERROR,
        status: "error",
      });
    }
  };

  const openQueryBuilder = () => {
    mobileSideNav.onClose();
    onBuildModalOpen();
  };

  const openSavedQueries = () => {
    mobileSideNav.onClose();
    onQueriesModalOpen();
  };

  const QueryResultsHeader = () => (
    <HStack justify="space-between" align="center" w="full" h="3rem" pb={2}>
      <HStack spacing={4} align="center">
        <Text fontSize="lg" fontWeight="semibold" colorScheme="gray">
          Query Results
        </Text>
        <HStack spacing={1} align="center">
          <Icon as={AiFillCheckCircle} color="green.400" />
          <Text fontSize="sm" fontWeight="medium" color={secondaryTextColor}>
            {state.totalRows} Rows
          </Text>
        </HStack>
        {state.totalRows && state.tableData.length && (
          <>
            <Button
              leftIcon={<BiExport />}
              colorScheme="brand"
              variant="outline"
              size="xs"
              onClick={handleExportTable}
            >
              Export
            </Button>
            <CSVLink
              data={state.exportData}
              filename={`${state.selectedTable}.csv`}
              className="hidden"
              ref={csvLink}
              target="_blank"
            />
          </>
        )}
      </HStack>
      {state.tableData.length && (
        <Pagination
          pages={pages}
          pagesCount={pagesCount}
          currentPage={currentPage}
          isDisabled={state.isLoading}
          setCurrentPage={handlePageChange}
        />
      )}
    </HStack>
  );

  const CardButton = ({ onClick, icon, title, subtitle }) => (
    <LinkBox
      p="5"
      borderWidth="1px"
      rounded="md"
      onClick={onClick}
      _hover={{
        borderColor: brandColor,
        transform: "scale(1.025)",
      }}
    >
      <HStack h="full" spacing={2} align="center" justify="center">
        {icon}
        <VStack align="flex-start" spacing={1}>
          <LinkOverlay href="#">
            <HStack align="center" spacing={2}>
              <Heading size="sm">{title}</Heading>
              <CustomTag text={state.selectedTable} />
            </HStack>
          </LinkOverlay>
          <Text>{subtitle}</Text>
        </VStack>
      </HStack>
    </LinkBox>
  );

  const RenderSideContent = () => (
    <Stack direction="column" spacing={8} py="6" minWidth="300px">
      <VStack spacing={1} align="flex-start">
        <Text fontSize="sm" fontWeight="semibold">
          SCHEMA
        </Text>
        <Tag
          fontSize="sm"
          colorScheme="brand"
          fontFamily="monospace"
          fontWeight="semibold"
        >
          {schemaName}
        </Tag>
      </VStack>
      <VStack spacing={0} align="flex-start">
        <VStack spacing={1} align="flex-start">
          <Text fontSize="sm" fontWeight="semibold">
            TABLE/VIEW
          </Text>
          {/* <Select
            w="full"
            size="sm"
            color={brandColor}
            variant="outline"
            borderRadius="md"
            isFullWidth="true"
            borderColor={brandColor}
            fontWeight="semibold"
            fontFamily="monospace"
            value={state.selectedTable}
            onChange={handleTableChange}
          >
            {tables.map((table) => (
              <option value={table} key={table}>
                {table}
              </option>
            ))}
          </Select> */}
          <div>
            <Menu>
              <MenuButton as={Button}
                colorScheme="blue" variant="outline"
                size="sm"
                color={brandColor}
                borderRadius="md"
                width="220px"
                maxWidth="220px"
                textAlign="left"
                borderColor={brandColor}
                fontWeight="semibold"
                fontFamily="monospace"
                overflow="hidden"
                whiteSpace="nowrap"
                isTruncated
              >
                <Flex justify="space-between" align="center" width="100%">
                  <Box
                    isTruncated
                    maxW="90%"
                    overflow="hidden"
                    whiteSpace="nowrap"
                    textAlign="left"
                  >
                    {state.selectedTable || "Select Table"}
                  </Box>
                  <Icon as={ChevronDownIcon} ml="2" />
                </Flex>
              </MenuButton>
              <MenuList>
                {Object.entries(groupedTables).map(([category, tables]) => (
                  <HoverMenu key={category} category={category} tables={tables} onChange={handleTableChange} />
                ))}
              </MenuList>
            </Menu>
          </div>
        </VStack>
        <VStack spacing={0} align="flex-start" pl={4} justify="space-evenly">
          <LinkBox
            pt={2}
            h={10}
            pl={2}
            fontSize="sm"
            _hover={{
              color: brandColor,
            }}
            borderColor="grey"
            borderLeftWidth="1px"
            _focus={{ outline: 0 }}
            onClick={openQueryBuilder}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <LinkOverlay href="#">
              <HStack align="center">
                <Icon
                  boxSize={5}
                  as={IoBuildOutline}
                  sx={{ transform: "rotate(-45deg)" }}
                />
                <Text>Query Builder</Text>
              </HStack>
            </LinkOverlay>
          </LinkBox>
          <LinkBox
            h={10}
            pl={2}
            fontSize="sm"
            borderColor="grey"
            _hover={{
              color: brandColor,
              fontStyle: "bold",
            }}
            borderLeftWidth="1px"
            _focus={{ outline: 0 }}
            onClick={openSavedQueries}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <LinkOverlay href="#">
              <HStack align="center">
                <Icon boxSize={5} as={HiViewList} />
                <Text>Saved Queries</Text>
              </HStack>
            </LinkOverlay>
          </LinkBox>
        </VStack>
      </VStack>
    </Stack>
  );

  const RenderMain = () => {
    if (!state.tableColumns.length && !state.tableData.length) {
      return (
        <Center h="full" spacing={6}>
          <Stack direction={{ base: "column", md: "row" }} spacing={4}>
            <CardButton
              onClick={onBuildModalOpen}
              icon={
                <Icon
                  as={IoBuildOutline}
                  boxSize={{ base: 8, md: 10 }}
                  sx={{ transform: "rotate(-45deg)" }}
                />
              }
              title="Query Builder"
              subtitle="Build, preview, run, and save queries."
            />
            <CardButton
              onClick={onQueriesModalOpen}
              icon={<Icon as={HiViewList} boxSize={{ base: 8, md: 10 }} />}
              title="Saved Queries"
              subtitle="Run, edit, or delete the saved queries."
            />
          </Stack>
        </Center>
      );
    } else {
      return (
        <>
          <QueryResultsHeader />
          <QueryResultsTable />
        </>
      );
    }
  };

  return (
    <MainLayout>
      <SidebarLayout
        mobileSideNav={mobileSideNav}
        sidebarContent={<RenderSideContent />}
        mainContent={
          <VStack spacing={0} w="full" minH="full">
            <RenderMain />
          </VStack>
        }
      />
      {/* Query Builder/SQL Preview modal */}
      <ModalDialog
        modalProps={{
          size: "5xl",
        }}
        isModalOpen={isBuildModalOpen}
        onModalClose={resetMode}
        modalTitle={
          state.mode.type === "new" ? (
            <Text>New Query</Text>
          ) : (
            <Text>Edit Query - {state.mode.query.name}</Text>
          )
        }
        modalBody={
          <Tabs
            isLazy
            colorScheme="brand"
            variant="enclosed-colored"
            onChange={(index) =>
              dispatch({
                type: SET_TAB,
                payload: index,
              })
            }
            index={state.selectedTab}
          >
            <TabList
              sx={{
                display: 'flex', justifyContent: 'space-between', alignItems: 'center'
              }}
            >
              {/* <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}> */}
              <TabList style={{ display: 'flex', alignItems: 'center' }}>
                <Tab>Query Builder</Tab>
                <Tab>SQL Preview</Tab>
                <CustomTag text={state.selectedTable} ml={2} />
              </TabList>
              {state.query.rules.length === 0 && <div>
                {/* <input
                  type="date"
                  value={inputDateStart}
                  onChange={handleDateChangeStart}
                  defaultValue="2024-09-01"
                  style={{ marginLeft: 'auto' }}

                />
                <input
                  type="date"
                  value={inputDateEnd}
                  onChange={handleDateChangeEnd}
                  defaultValue="2024-09-15"
                  style={{ marginLeft: 'auto' }}

                /> */}
                <CustomTag text="Date Range:" mr={2} mt={1.5} mb={2} />
                <DatePicker
                  showIcon
                  onChange={update => {
                    setInputDateStart(update[0]);
                    setInputDateEnd(update[1]);
                  }
                  }
                  startDate={inputDateStart}
                  endDate={inputDateEnd}
                  selectsRange
                  dateFormat="yyyy-MM-dd"
                  icon={<CalendarIcon color="gray.500"
                    viewBox="0 0 16 16"
                    mr={2}
                    mt={1}
                    mb={0}
                  />}
                />
              </div>}
              {/* </div> */}
            </TabList>
            <TabPanels>
              <TabPanel p={0} pb={0}>
                <CustomTabPanel
                  content={
                    <TableQueryBuilder
                      query={state.query}
                      fields={state.columns}
                      onQueryChange={handleOnQueryChange}
                    />
                  }
                  button={<QueryBuilderActionButton type={state.mode.type} />}
                />
              </TabPanel>
              <TabPanel p={0} pb={0}>
                <CustomTabPanel
                  content={
                    <SyntaxHighlighter
                      customStyle={{
                        background: "transparent",
                      }}
                      className="syntax-highlighter"
                      language="sql"
                      wrapLongLines
                      style={codeTheme}
                    >
                      {format(sqlPreviewQuery)}
                    </SyntaxHighlighter>
                  }
                  button={<QueryBuilderActionButton type={state.mode.type} />}
                />
              </TabPanel>
            </TabPanels>
          </Tabs>
        }
        modalPrimaryActionText="Run Query"
        modalPrimaryActionProps={{
          onClick: runQuery,
          isLoading: state.isQuerying,
          disabled: state.isQuerying || !iterate(state.query),
          spinnerPlacement: "end",
        }}
      />
      {/* Exporting Query Results modal */}
      <ModalDialog
        modalProps={{ closeOnOverlayClick: false }}
        isModalOpen={isExportModalOpen}
        onModalClose={abortExportData}
        modalTitle="Exporting Query Results"
        modalBody={
          <Progress
            hasStripe
            colorScheme="brand"
            value={(state.exportData.length / (pagesCount * 50)) * 100}
          />
        }
        hasFooter={false}
      />
      {/* Save Query modal */}
      <ModalDialog
        isModalOpen={isSaveModalOpen}
        onModalClose={onSaveModalClose}
        modalTitle="Save Query"
        modalBody={
          <form onSubmit={handleSubmit(createSavedQuery)}>
            <VStack>
              <FormControl isInvalid={errors.name}>
                <FormLabel htmlFor="name">Query name</FormLabel>
                <Input
                  id="name"
                  {...register("name", {
                    required: "Query name is required",
                    validate: (value) =>
                      !state.savedQueries
                        .filter((query) => query.table === state.selectedTable)
                        .some((el) => el.name === value) ||
                      "Name already present",
                  })}
                />
                <FormErrorMessage>
                  {errors.name && errors.name.message}
                </FormErrorMessage>
              </FormControl>
              <Button
                size="sm"
                type="submit"
                colorScheme="brand"
                alignSelf="flex-end"
                isLoading={isSubmitting}
              >
                Save
              </Button>
            </VStack>
          </form>
        }
        hasFooter={false}
      />
      {/* Saved Queries modal */}
      <ModalDialog
        modalProps={{
          size: "5xl",
        }}
        isModalOpen={isQueriesModalOpen}
        onModalClose={onQueriesModalClose}
        modalBody={
          <SavedQueriesTable
            selectedTable={state.selectedTable}
            savedQueries={state.savedQueries}
            runSavedQuery={runSavedQuery}
            editSavedQuery={editSavedQuery}
            deleteSavedQuery={deleteSavedQuery}
            isLoading={state.loadingSavedQueries}
          />
        }
        hasFooter={false}
      />
    </MainLayout>
  );
};

export default QueryPage;
